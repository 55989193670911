




































































import { Component, Mixins, Ref, Vue } from "vue-property-decorator";
import Cookies from "js-cookie";
import { uuid } from "vue-uuid";
import { AxiosResponse } from "axios";
import Main from "@/views/Main.vue";
import { cantMoving } from "@/main";
import PdfLinkShow from "@/views/pdf/PdfLinkShow.vue";
import OfficeSelect from "@/components/app/OfficeSelect.vue";
import Login from "@/components/app/login/Login.vue";
import LoginLink from "@/components/app/login/trash/LoginLink.vue";
import AccountConnect from "@/components/app/login/trash/AccountConnect.vue";
import PasswordRemind from "@/components/app/login/PasswordRemind.vue";
import PasswordRemindCheckCode from "@/components/app/login/PasswordRemindCheckCode.vue";
import PasswordRemindChange from "@/components/app/login/PasswordRemindChange.vue";
import Maintenance from "@/components/app/Maintenance.vue";
import { MaintenanceInfo, DefaultMaintenanceInfo } from "#/model/admin";
import AxiosMixin from "@/mixins/axiosMixin";
import AppConfirm from "#/components/AppAlertConfirm.vue";
import OtherPageOpen from "@/components/app/OtherPageOpen.vue";
import { SelectOffice } from "@/components/app/types";
import TokenMixin from "#/mixins/tokenMixin";

window.axios = require("axios");

window.base_url = process.env.VUE_APP_BACKEND_URL;
if (!window.base_url) {
  window.base_url = "http://localhost:8080";
}

window.base_heavy_url = process.env.VUE_APP_BACKEND_HEAVY_URL;
if (!window.base_heavy_url) {
  window.base_heavy_url = "http://localhost:8080";
}

window.auth_backend_url = process.env.VUE_APP_AUTH_BACKEND_URL;
if (!window.auth_backend_url) {
  window.auth_backend_url = "http://localhost:8085";
}

window.chat_backend_url = process.env.VUE_APP_CHAT_BACKEND_URL;
if (!window.chat_backend_url) {
  window.chat_backend_url = "http://localhost:8081";
}

window.auth_middleend_url = process.env.VUE_APP_AUTH_MIDDLEEND_URL;
if (!window.auth_middleend_url) {
  window.auth_middleend_url = "http://localhost:8085";
}

window.auth_frontend_url = process.env.VUE_APP_AUTH_FRONTEND_URL;
if (!window.auth_frontend_url) {
  window.auth_frontend_url = "http://localhost:9200";
}

window.cookie_domain = process.env.VUE_APP_COOKIE_DOMAIN;
if (!window.cookie_domain) {
  window.cookie_domain = "localhost";
}

window.mode = process.env.VUE_APP_MODE;
if (!window.mode) {
  window.mode = "dev";
}

window.app_version = process.env.VUE_APP_VERSION;
if (!window.app_version) {
  window.app_version = "A";
}

window.portal_frontend_url = process.env.VUE_APP_PORTAL_FRONTEND_URL;
if (!window.portal_frontend_url) {
  window.portal_frontend_url = "http://localhost:3000";
}

const displayCarelog = process.env.VUE_APP_DISPLAY_CARELOGOOD_BUTTON;
if (displayCarelog == "") {
  window.display_carelogood_ageement_id = 0;
} else {
  window.display_carelogood_ageement_id = Number(displayCarelog);
}

window.axios.defaults.headers.common["APP_VERSION"] = window.app_version;

@Component({
  components: {
    Main,
    PdfLinkShow,
    OfficeSelect,
    Login,
    LoginLink,
    AccountConnect,
    PasswordRemind,
    PasswordRemindCheckCode,
    PasswordRemindChange,
    Maintenance,
    AppConfirm,
    OtherPageOpen
  }
})
export default class App extends Mixins(AxiosMixin, TokenMixin) {
  /** トークン認証済みかどうか */
  private isToken = false;

  /** 職員選択済みかどうか */
  private isStaff = false;

  /** 専用画面かどうか 1:契約別ログイン画面 2:他システム連携ログイン画面 3:他システム連携画面 4:別ページ表示画面 5:PDFリンク表示画面 */
  private otherLogin = 0;

  /** パスワードリセット画面の種類 0:非表示 1:パスワード再設定画面 2:セキュリティコード確認画面 3:新規パスワード入力画面 */
  private passwordResetType = 0;

  /** ローディング */
  private pageloading = false;

  /** メンテナンス画面かどうか */
  private isMainte = false;

  /** メンテナンス情報 */
  private maintenance_info: MaintenanceInfo = DefaultMaintenanceInfo();

  /** 別ページメッセージ */
  private message = "";

  @Ref("app_confirm") private readonly appConfirm!: AppConfirm;

  created() {
    //フィンガープリント
    const fingerprint = Cookies.get("fingerprint");
    if (fingerprint) {
      window.fingerprint = fingerprint;
    } else {
      //ランダム文字列ない場合は作る
      window.fingerprint = uuid.v4();
    }
    Cookies.set("fingerprint", window.fingerprint, {
      domain: window.cookie_domain,
      expires: 365
    });
    window.axios.defaults.headers.common["SessionId"] = window.fingerprint;

    //ワンタイムトークン確認
    if (location.href.indexOf("?token=") != -1) {
      const st = location.href.indexOf("?token=");
      const en = location.href.length;
      this.adminOnetime(
        window.auth_backend_url,
        window.axios,
        location.href.substring(st + 7, en),
        this.setOnetimeToken
      );
      return;
    }

    //ユーザーワンタイムトークン確認
    if (location.href.indexOf("?user_token=") != -1) {
      const st = location.href.indexOf("?user_token=");
      const en = location.href.length;
      this.userOnetime(
        window.auth_backend_url,
        window.axios,
        location.href.substring(st + 12, en),
        this.setOnetimeToken
      );
      return;
    }

    //ログイン企業コード
    const coCode = Cookies.get("login_co_code");
    if (coCode) {
      window.login_co_code = coCode;
    }

    //トークン
    const token = Cookies.get("token");
    if (token) {
      window.token = token;
    }

    //リフレッシュトークン
    const reftoken = Cookies.get("reftoken");
    if (reftoken) {
      window.reftoken = reftoken;
      this.isToken = true;
      this.isStaff = true;
    } else if (coCode && location.pathname.indexOf("/login") != 0) {
      this.$router.replace({
        path: "/login/" + coCode + location.search
      });
      window.login_co_code = "";
      Cookies.set("login_co_code", "", { domain: window.cookie_domain });
    }

    //PDF表示画面の場合
    if (location.pathname.indexOf("/pdf-open") == 0) {
      this.message = "PDFを表示しています...";
      this.otherLogin = 4;
    }

    //ファイル表示画面の場合
    if (location.pathname.indexOf("/file-open") == 0) {
      this.message = "ファイルを表示しています...";
      this.otherLogin = 4;
    }

    //PDFリンクメールから遷移する、PDF表示画面である場合
    if (location.pathname.indexOf("/pdflink/view/") === 0) {
      this.otherLogin = 5;
    }
  }

  mounted() {
    // this使えないので、参照を保持
    const dialog = this.appConfirm;

    // アラートダイアログ呼び出し用インスタンスプロパティ作成
    Vue.prototype.$openAlert = async function(
      text: string,
      saveLabel = "OK"
    ): Promise<boolean> {
      dialog.isConfirm = false;
      const res = await dialog.open(text, saveLabel);
      return res;
    };

    // 確認ダイアログ呼び出し用インスタンスプロパティ作成
    Vue.prototype.$openConfirm = async function(
      text: string,
      saveLabel = "OK",
      cancelLabel = "キャンセル"
    ): Promise<boolean> {
      dialog.isConfirm = true;
      const res = await dialog.open(text, saveLabel, cancelLabel);
      return res;
    };
  }

  /** 職員切り替え */
  private async setStaff(select: SelectOffice) {
    // 編集を破棄したくない場合は処理しない
    if (await cantMoving()) {
      return;
    }

    window.token = select.token;
    Cookies.set("token", select.token, { domain: window.cookie_domain });
    window.reftoken = select.ref_token;
    Cookies.set("reftoken", select.ref_token, { domain: window.cookie_domain });
    location.href = "/";
  }

  /** ログイン */
  private login(coCode = "") {
    this.isToken = true;
    this.isStaff = true;
    this.otherLogin = 0;
    window.login_co_code = coCode;
    Cookies.set("login_co_code", coCode, { domain: window.cookie_domain });

    // オペログ書き込み用
    this.postJsonBackground(window.base_url + "/api/login", {}, () => {
      /* do nothing */
    });

    this.$router.push({ path: "/" });
  }

  /** ログアウト画面へ遷移 */
  private async toLogout() {
    // 編集を破棄したくない場合は処理しない
    if (await cantMoving()) {
      return;
    }

    // オペログ書き込み用
    this.postJsonBackground(window.base_url + "/api/logout", {}, () => {
      /* do nothing */
    });

    // セッション削除
    this.pageloading = true;
    this.postJsonCheck(window.auth_backend_url + "/api/auth/logout", {}, () => {
      this.pageloading = false;
      window.token = "";
      Cookies.set("token", "", { domain: window.cookie_domain });
      window.reftoken = "";
      Cookies.set("reftoken", "", { domain: window.cookie_domain });
      location.href = "/?action=logout";
    });
  }

  /** トークンを設定する */
  private setOnetimeToken(
    res: AxiosResponse<{ token: string; ref_token: string }>
  ) {
    window.token = res.data.token;
    Cookies.set("token", res.data.token, { domain: window.cookie_domain });
    window.reftoken = res.data.ref_token;
    Cookies.set("reftoken", res.data.ref_token, {
      domain: window.cookie_domain
    });
    this.login();
  }
}
