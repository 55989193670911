




































import { Component, Vue, Emit, Watch } from "vue-property-decorator";
import LoginTitle from "@/components/app/login/LoginTitle.vue";
import LoginArea from "@/components/app/login/LoginArea.vue";
import DoubleLoginArea from "@/components/app/login/DoubleLoginArea.vue";
import { AxiosError, AxiosResponse } from "axios";
import { Route } from "vue-router";

@Component({
  components: { LoginTitle, LoginArea, DoubleLoginArea }
})
export default class Login extends Vue {
  /** パスワードリセット処理 */
  @Emit() private passwordReset() {
    return;
  }

  /** ログイン終了 */
  @Emit() private login(coCode: string) {
    return coCode;
  }

  /** 2段階認証モード */
  private isDoubleLogin = false;

  /** ローディング */
  private loading = false;

  /** 契約ID */
  private agreement_id = 0;

  /** 企業コード */
  private co_code = "";

  /** 契約名 */
  private name = "";

  /** EweLLUserID */
  private ewellUserId = 0;

  /** ログアウト後のログイン画面かどうか */
  private get wasLogout(): boolean {
    return this.$route.query.action === "logout";
  }

  private get IframeSrc(): string {
    return (
      "https://infomationlogin.ibowservice.jp/?time=" + new Date().getTime()
    );
  }

  created() {
    this.getLoginInfo(location.pathname);
  }

  @Watch("$route")
  watchRoute(to: Route) {
    this.getLoginInfo(to.path);
  }

  getLoginInfo(url: string) {
    const reg = url.match(/\/login\/(.+)/);
    if (reg) {
      this.co_code = reg[1];
    }
    if (this.co_code) {
      this.loading = true;
      window.axios
        .post(window.base_url + "/get-login-info", {
          co_code: this.co_code
        })
        .then((res: AxiosResponse) => {
          this.loading = false;
          this.name = res.data.agreement_name;
          this.agreement_id = res.data.agreement_id;
        })
        .catch((error: AxiosError) => {
          this.loading = false;
          console.log(error);
          location.href = "/";
        });
    }
  }

  /** ログイン終了 */
  private finishLogin() {
    if (this.$route.query.return_url) {
      location.href = this.$route.query.return_url as string;
    } else {
      this.login(this.co_code);
    }
  }

  /** 2段階認証 */
  private continuedLogin(ewellUserId: number) {
    this.ewellUserId = ewellUserId;
    this.isDoubleLogin = true;
  }
}
